<template>
  <div id="app">
    <Navbar v-if="showNavbar" />

    <div id="main">
      <router-view />
    </div>

    <Footer v-if="showFooter" />

    <CallMe />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import CallMe from "@/components/CallMe.vue";

export default {
  name: "smaterials",
  components: {
    Navbar,
    Footer,
    CallMe,
  },
  computed: {
    showNavbar() {
      return this.$store.state.navbar;
    },
    showFooter() {
      return this.$store.state.footer;
    },
  },
};
</script>
