import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";

import "mutationobserver-shim";
import "./plugins/bootstrap-vue";

// Swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";

Vue.use(VueAwesomeSwiper, {
  Swiper,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
