<template>
  <div class="more_info">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="4"
          v-for="(item, index) in moreInfo"
          :key="index"
          class="more_info__card"
        >
          <div
            class="more_info__card_wrapper"
            :style="{
              'background-image':
                'url(' + require('@/assets/' + item.img) + ')',
            }"
          >
            <div>
              <h2 class="more_info__title title">{{ item.title }}</h2>
              <p class="more_info__description text_small">
                {{ item.description }}
              </p>
              <b-row align-h="center">
                <router-link
                  :to="item.link"
                  id="button_outline"
                  class="text-center"
                  >Подробнее</router-link
                >
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moreInfo: [
        {
          title: "История создания",
          description: "У роста нет границ.",
          img: "photo/_S__3131.jpg",
          link: "company?slide=0#story",
        },
        {
          title: "Ценности компании",
          description: "Помогая другим, обретаешь сам.",
          img: "photo/_S__3378.jpg",
          link: "company?slide=1#story",
        },
        {
          title: "Учредители",
          description: "Синергия опыта - гарант эффективности.",
          img: "img/moreInfo/three.jpg",
          link: "company?slide=4#story",
        },
        {
          title: "Наша команда",
          description: "Ценность компании в людях.",
          img: "photo/_S__3164.jpg",
          link: "company?slide=3#story",
        },
        {
          title: "Стандарты компании",
          description: "Превосходим ожидания.",
          img: "photo/_S__3299.jpg",
          link: "company?slide=2#story",
        },
        {
          title: "Документация",
          description: "Лицензии и сертификаты",
          img: "img/moreInfo/six.png",
          link: "company?slide=5#story",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.more_info {
  padding-bottom: 100px;
}
.more_info__card {
  padding-bottom: 30px;
}
/* dop-info */
.more_info__card_wrapper {
  height: 220px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: linear;
  transition-duration: 100ms;

  background-color: rgba($gray-dark, 0.823);
  background-size: cover;
  background-blend-mode: saturation;
}
.more_info__card_wrapper:hover {
  transition: linear;
  transition-duration: 100ms;
  background-color: rgba($gray-dark, 0.4);
}
.more_info__card_wrapper:hover #button_outline {
  background-color: $accent;
}

.more_info__description {
  text-align: center;
  padding-bottom: 15px;
}

.more_info__title {
  font-size: 24px;
  margin: 0;
  text-align: center;
}
</style>
