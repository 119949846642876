<template>
  <div>
    <!-- Desktop -->
    <div class="d-none d-lg-block">
      <div id="footer">
        <b-container>
          <b-row align-h="between" align-v="center">
            <b-col cols="auto">
              <div class="logo_data">
                <button type="button" @click="to('Home')" class="logo_wrapper">
                  <img
                    class="logo_img"
                    :src="require('@/assets/svg/logo-smaterials.svg')"
                    alt=""
                  />
                </button>

                <p class="logo_title">С-МАТЕРИАЛС</p>
              </div>
            </b-col>

            <b-col cols="auto">
              <b-row>
                <div id="block_footer">
                  <router-link to="/company"><p>О компании</p></router-link>
                  <router-link to="/services"><p>Наши услуги</p></router-link>
                  <!-- <router-link  to="/"
                    ><p>Исполненные контракты</p></router-link
                  > -->
                  <!-- <router-link  to="/"
                    ><p>Стандарты качества</p></router-link
                  > -->
                </div>

                <div id="block_footer">
                  <!-- <router-link  to="/"
                    ><p>Сертификаты</p></router-link
                  > -->
                  <router-link to="/company?slide=5#story"
                    ><p>Документация</p></router-link
                  >
                  <!-- <router-link  to="/company?slide=0#story"
                    ><p>История</p></router-link
                  > -->
                  <router-link to="/company?slide=1#story"
                    ><p>Ценности</p></router-link
                  >
                </div>

                <div id="block_footer">
                  <router-link to="/partner"
                    ><p>Стать нашим партнером</p></router-link
                  >
                  <router-link to="/applicant"
                    ><p>Стать частью команды</p></router-link
                  >
                </div>
              </b-row>
            </b-col>

            <b-col cols="auto">
              <div
                align-h="center"
                align-v="center"
                class="footer_callme__wrapper"
              >
                <a class="img_call__wrapper">
                  <img
                    v-b-modal.callme
                    class="img_call__img"
                    :src="require('@/assets/svg/icon-phone.svg')"
                  />
                </a>

                <div class="contact_info__block">
                  <p class="contact_info">+7 (965) 897 05-51</p>
                  <p class="contact_info">
                    г. Красноярск, ул. Партизана Железняка, 35А
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div id="footer_line">
        <b-container>
          <b-row align-h="between" align-v="center">
            <b-col cols="auto">
              <p class="footer_line__title">2020-2023 © C-МАТЕРИАЛС</p>
            </b-col>

            <b-col cols="auto">
              <b-row align-v="center" align-h="end">
                <div class="soc_icons__wrapper">
                  <a
                    class="icon_soc__wrapper"
                    href="https://www.instagram.com/supplymaterials/"
                    target="_blank"
                  >
                    <img
                      class="icon_soc__img"
                      :src="require('@/assets/img/icons/social/icon_inst.png')"
                      alt="instagram"
                    />
                  </a>
                  <a
                    class="icon_soc__wrapper"
                    href="https://vk.com/s_materials"
                    target="_blank"
                  >
                    <img
                      class="icon_soc__img"
                      :src="require('@/assets/img/icons/social/icon_vk.png')"
                      alt="vk"
                    />
                  </a>
                </div>
              </b-row>
            </b-col>

            <b-col cols="auto">
              <a
                class="footer_line__link"
                href="https://drive.google.com/file/d/1C-T9vHIKbPH2JVoF98Un-nx83VlfmZ3F/view"
                target="_blank"
                >Правовая информация</a
              >
              <a
                class="footer_line__link"
                href="https://drive.google.com/file/d/1EPHE6D8lIAnHnfFFbsZPRLm-I8h-nRDK/view"
                target="_blank"
                >Политика конфиденциальности компании</a
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <!-- mobile -->
    <div class="d-lg-none">
      <div id="footer">
        <b-container>
          <b-row align-h="center" align-v="center" class="call_logo_wrapper">
            <div class="callme__wrapper_logo">
              <img
                class="callme__logo"
                :src="require('@/assets/svg/logo-smaterials.svg')"
                alt=""
              />
            </div>

            <p class="logo_title">С-МАТЕРИАЛС</p>
          </b-row>

          <b-row align-v="center" align-h="center">
            <div id="links_mobile_footer">
              <router-link to="/company"><p>О компании</p></router-link>
              <router-link to="/services"><p>Наши услуги</p></router-link>
              <router-link to="/company?slide=5#story"
                ><p>Документация</p></router-link
              >
              <router-link to="/company?slide=1#story"
                ><p>Ценности</p></router-link
              >
              <router-link to="/partner"
                ><p>Стать нашим партнером</p></router-link
              >
              <router-link to="/applicant"
                ><p>Стать частью команды</p></router-link
              >
            </div>
          </b-row>

          <b-row align-h="center">
            <a v-b-modal.callme class="mobile_navbar__callme_wrapper">
              <img
                class="mobile_navbar__callme"
                :src="require('@/assets/svg/icon-phone.svg')"
              />
            </a>
          </b-row>

          <b-row align-h="center" id="modal_menu_contact">
            <div class="contact_info__block">
              <p class="contact_info text-center">+7 (965) 897 05-51</p>
              <p class="contact_info text-center">
                г. Красноярск,<br />
                ул. Партизана Железняка, 35А
              </p>
            </div>
          </b-row>

          <b-row align-h="center" align-v="center" class="block_soc">
            <div class="col d-flex justify-content-center align-items-center">
              <a
                href="https://www.instagram.com/supplymaterials"
                target="_blank"
              >
                <img
                  id="icon_soc_mobile"
                  :src="require('@/assets/img/icons/social/icon_inst.png')"
                  alt="instagram"
                />
              </a>

              <a
                href="https://vk.com/s_materials"
                target="_blank"
                >
                <img
                  id="icon_soc_mobile"
                  :src="require('@/assets/img/icons/social/icon_vk.png')"
                  alt="vk"
                />
              </a>
            </div>
          </b-row>
        </b-container>
      </div>

      <div id="footer_line">
        <div id="mobile" class="container">
          <b-row align-h="center" align-v="center" id="footer_line_block">
            <p class="text-center">2020-2023 © C-Материалс</p>
          </b-row>

          <b-row align-h="center">
            <a
              class="footer_line__link"
              href="https://drive.google.com/file/d/1C-T9vHIKbPH2JVoF98Un-nx83VlfmZ3F/view"
              target="_blank"
              >Правовая информация</a
            >
          </b-row>

          <b-row align-h="center">
            <a
              class="footer_line__link"
              href="https://drive.google.com/file/d/1EPHE6D8lIAnHnfFFbsZPRLm-I8h-nRDK/view"
              target="_blank"
              >Политика конфиденциальности компании</a
            >
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    to(name) {
      if (this.$route.name != name) this.$router.push({ name: name });
    },
  },
};
</script>

<style lang="scss">
#footer {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: $gray;
}
#block_footer {
  padding-right: 30px;
}
#block_footer p {
  font-size: 14px;
  font-weight: 400;
  color: rgba($white, 0.8);
  text-decoration: none;
  margin-bottom: 8px;
}
#block_footer #icon_soc {
  margin-left: 0px;
  margin-right: 0px;
  width: 32px;
  filter: opacity(80%);
}
#block_footer #icon_soc:hover {
  filter: opacity(100%);
}
#block_footer a:hover {
  text-decoration: none;
}
#block_footer a:hover p {
  color: rgba($white, 1);
}
#footer #logo {
  height: 54px;
}
#footer_line {
  padding: 8px;
  color: rgba($white, 0.8);
  background-color: $gray-dark;
}
#footer_line p {
  font-size: 14px;
  margin-bottom: 0;
}

#footer_line a:hover {
  color: $white;
}

#links_mobile_footer {
  text-align: center;
  margin-bottom: 30px;
}
#links_mobile_footer a,
p {
  color: $white;
}
#links_mobile_footer a:hover p {
  text-decoration: none;
}
#links_mobile_footer a:hover {
  text-decoration: none;
}
#footer_mobile_logo #logo {
  margin-bottom: 30px;
  height: 64px;
}

#icon_soc_mobile {
  width: 48px;
  margin: 5px;
}
#modal_menu_contact {
  padding-bottom: 15px;
}
#footer_line #mobile a {
  margin: 0px;
}
#footer_line_block {
  margin-bottom: 15px;
}
#mobile {
  padding-top: 15px;
}

.contact_info__block {
  padding-left: 15px;
}
.contact_info {
  margin: 0;
}

.img_call__wrapper {
  height: 40px;
  width: 40px;
  overflow: hidden;
  position: relative;
}
.img_call__img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.soc_icons__wrapper {
  display: flex;
  margin: 0 auto;
}
.icon_soc__wrapper {
  display: block;
  height: 22px;
  width: 22px;
  position: relative;
  overflow: hidden;
  margin: 0 5px;
}
.icon_soc__img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  filter: opacity(50%);
}
.icon_soc__img:hover {
  filter: opacity(70%);
}

.footer_line__link {
  color: rgba($color: $white, $alpha: 0.5);
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.footer_line__link:hover {
  color: rgba($color: $white, $alpha: 0.7);
}
.footer_callme__wrapper {
  display: flex;
}
.footer_line__title {
  font-size: 14px;
  font-weight: 600;
  color: rgba($color: $white, $alpha: 0.7);
}
</style>
