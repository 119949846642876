<template>
  <div class="doings">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" class="doings_wrapper_left">
          <b-row align-h="center" align-v="center">
            <img
              class="doings_icon"
              src="@/assets/svg/icon-box.svg"
              alt="Коробка"
            />
          </b-row>

          <h2 class="doings_title title text-center">Что делаем?</h2>

          <div class="popup_text__wrapper">
            <a
              class="text-small text-center text_popup_trigger"
              @click="showHideText(whatDoing)"
            >
              Осуществляем комплексные<br />
              поставки товаров и услуг
            </a>

            <button @click="showHideText(whatDoing)" class="popup_text__circle">
              <p class="circle_symbol">i</p>
            </button>
          </div>

          <p class="popup_text" v-if="whatDoing.show">{{ whatDoing.txt }}</p>
        </b-col>

        <b-col cols="12" md="6" class="doings_wrapper_right">
          <b-row align-h="center" align-v="center">
            <img
              class="doings_icon"
              src="@/assets/svg/icon-man.svg"
              alt="Менеджер"
            />
          </b-row>

          <h2 class="doings_title title text-center">Для кого делаем?</h2>

          <div class="popup_text__wrapper">
            <a
              class="text-small text-center text_popup_trigger"
              @click="showHideText(whoDoing)"
            >
              Наши клиенты – преимущественно <br />
              крупный бизнес федерального уровня.
            </a>

            <button class="popup_text__circle" @click="showHideText(whoDoing)">
              <p class="circle_symbol">i</p>
            </button>
          </div>

          <p class="popup_text" v-if="whoDoing.show">{{ whoDoing.txt }}</p>
        </b-col>
      </b-row>

      <b-row class="doings_description" align-h="center" align-v="center">
        <h2 class="italic_accent__subtitle">
          С-МАТЕРИАЛС - партнёрство с высоким уровнем сервиса
        </h2>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Doings",
  data() {
    return {
      whatDoing: {
        show: false,
        txt: `
        Спектр категорий товаров и услуг обширен и насчитывает более 1000 наименований.
        `,
      },
      whoDoing: {
        show: false,
        txt: `
        С каждым заказчиком мы выстраиваем доверительные и длительные отношения, ведущие к развитию и росту каждой из сторон. Делаем всё, чтобы подтверждать статус надёжного партнёра, на которого можно положиться в решении любых задач, связанных с тендерными поставками.
        `,
      },
    };
  },
  methods: {
    showHideText(popObj) {
      popObj.show = !popObj.show;
    },
  },
};
</script>

<style lang="scss">
.popup_text {
  position: absolute;
  z-index: 2;
  background-color: $gray;
  padding: 15px;
  border: 1px solid $gray-light;
}
.text_popup_trigger {
  cursor: pointer;
  display: flex;
}
.text_popup_trigger:hover {
  color: $accent;
}
.doings {
  padding-bottom: 100px;
}
.doings_wrapper_right {
  border-color: $gray-light;
  border-width: 1px;
  border-style: solid;
  padding: 50px;
  background-color: $gray;
}
.doings_wrapper_left {
  border-color: $gray-light;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  padding: 50px;
  background-color: $gray;
  position: relative;
}
.doings_title {
  padding-bottom: 15px;
}

.doings_description .doings_title {
  font-style: italic;
  color: $accent;
  padding-bottom: 0;
  font-weight: 400;
  font-size: 22px;
}
.doings_description {
  padding: 30px 0;
  background-color: $gray;
}
.doings_icon {
  max-height: 150px;
  padding-bottom: 30px;
}

.popup_text__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
.popup_text__circle {
  height: 16px;
  width: 16px;

  margin: 0 10px;

  background-color: $accent;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle_symbol {
  font-size: 14px;
  line-height: 14px;
  vertical-align: middle;
  margin: 0 auto;
  color: $black;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
}
.home_header_title {
  text-align: center;
  text-transform: uppercase;
}
</style>
