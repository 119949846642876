<template>
  <div class="deliveries">
    <LineInfo
      title="Поставки без границ"
      description="Осуществляем комплексные поставки в срок по всей территории России"
    />

    <b-container>
      <b-row> </b-row>

      <b-row align-h="center" align-v="center">
        <b-col cols="12" md="7">
          <img
            class="deliveries__map"
            :src="
              require('@/assets/svg/' + companyLogos[activeCompanyLogo].map)
            "
            alt=""
          />
        </b-col>

        <b-col cols="12" md="5">
          <b-row
            align-v="center"
            v-for="(item, index) in companyLogos"
            :key="index"
            class="deliveries__card_logos"
            :class="activeCompanyLogo == +index ? 'active' : ''"
            @click="changeActiveCompany(index)"
          >
            <b-col cols="2">
              <div class="deliveries__logo_wrapper">
                <img
                  class="deliveries__logo"
                  :src="require('@/assets/img/deliveries/' + item.img)"
                />
              </div>
            </b-col>

            <b-col cols="10">
              <p class="deliveries__label">{{ item.label }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
//import SwiperLogo from "@/components/SwiperLogo.vue";
import LineInfo from "@/components/LineInfo";

export default {
  name: "Deliveries",
  components: {
    LineInfo,
  },
  data() {
    return {
      activeCompanyLogo: 0,
      companyLogos: [
        {
          img: "nornikel.png",
          label: "ПАО «ГМК Норильский никель»",
          map: "map-1.svg",
        },
        {
          img: "rosneft.png",
          label: "ПАО «НК Роснефть»",
          map: "map-2.svg",
        },
        {
          img: "river.png",
          label: "АО «Енисейское речное пароходство»",
          map: "map-3.svg",
        },
        {
          img: "resh.png",
          label: "АО «ИСС» имени академика М. Ф. Решетнёва»",
          map: "map-4.svg",
        },
        {
          img: "rosatom.png",
          label: "ФГУП «НО РАО» Росатом",
          map: "map-4.svg",
        },
      ],
    };
  },
  methods: {
    changeActiveCompany(index) {
      this.activeCompanyLogo = index;
    },
  },
};
</script>

<style lang="scss">
// map
.deliveries__map {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 15px;
}
.deliveries__card_logos {
  background-color: $gray;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid $gray-light;

  margin-bottom: 10px;
  transition: all 300ms;
  cursor: pointer;
}
.deliveries__card_logos:hover {
  border-color: $gray__medium_light;
}
.deliveries__card_logos.active {
  border-color: $accent;
  transition: all 300ms;
}
.deliveries__logo_wrapper {
  height: 40px;
  position: relative;
  overflow: hidden;
}
.deliveries__logo {
  height: 100%;
  position: absolute;
  object-fit: contain;
}
.deliveries__label {
  margin: 0;
  font-size: 14px;
}

.deliveries {
  padding-bottom: 100px;
}
/* deliver */
.deliver_heading {
  padding-bottom: 30px;
}
.deliver_logo-company {
  width: 28%;
}
#map {
  width: 100%;
}
.deliver_text {
  color: $white;
  font-size: 16px;
  font-weight: 400;
}
.deliver__text_block {
  padding-bottom: 30px;
}

/* slider */
.deliver.slider_circle-control {
  padding-bottom: 30px;
}
.circle {
  height: 18px;
  width: 18px;

  border-radius: 100%;
  border-color: $white;
  border-style: solid;
  border-width: 1px;

  margin-right: 10px;

  cursor: pointer;
}
.circle._active {
  background-color: $accent;
}
.slider_logos {
  display: flex;
  justify-content: space-between;
}

.deliver.slider_logos {
  padding-bottom: 30px;
}
.deliver_title {
  margin: 0;
  padding-bottom: 15px;
}
.carousel_logos {
  padding-bottom: 30px;
}
</style>
