<template>
  <div class="news">
    <LineInfo
      title="Наши новости и статьи"
      description="Держим в курсе актуальных новостей"
    />

    <img id="cloudNews" :src="require('@/assets/img/cloud.png')" alt="" />

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper newsw">
              <div
                class="swiper-slide news"
                v-for="(news, index) in news"
                :key="index"
              >
                <div class="img-wrapper">
                  <img
                    :src="require('@/assets/img/news/' + news.img)"
                    class="news-img"
                    alt=""
                  />
                </div>
                <div class="news-content">
                  <p class="news-date">{{ news.date }}</p>
                  <h2 class="news-title">{{ news.title }}</h2>
                  <p class="news-subtitle">{{ news.subtitle }}</p>
                </div>
              </div>
            </div>

            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";

export default {
  name: "News",
  components: {
    LineInfo,
  },
  data() {
    return {
      news: [
        {
          title: "ПОСТУПЛЕНИЕ НОВЫХ ТОВАРОВ",
          subtitle: "Надежная мощность для вас",
          img: "magnit.png",
          date: "12.11.2020",
        },
        {
          title: "ПОСТУПЛЕНИЕ НОВЫХ ТОВАРОВ",
          subtitle: "Надежная мощность для вас",
          img: "sber.png",
          date: "12.11.2020",
        },
        {
          title: "ПОСТУПЛЕНИЕ НОВЫХ ТОВАРОВ",
          subtitle: "Надежная мощность для вас",
          img: "ozon.png",
          date: "12.11.2020",
        },
        {
          title: "ПОСТУПЛЕНИЕ НОВЫХ ТОВАРОВ",
          subtitle: "Надежная мощность для вас",
          img: "magnit.png",
          date: "12.11.2020",
        },
        {
          title: "ПОСТУПЛЕНИЕ НОВЫХ ТОВАРОВ",
          subtitle: "Надежная мощность для вас",
          img: "sber.png",
          date: "12.11.2020",
        },
        {
          title: "ПОСТУПЛЕНИЕ НОВЫХ ТОВАРОВ",
          subtitle: "Надежная мощность для вас",
          img: "ozon.png",
          date: "12.11.2020",
        },
      ],
      swiperOption: {
        cssMode: true,
        autoHeight: true,
        mousewheel: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  mounted() {
    //console.log('Current Swiper instance object', this.mySwiper)
    //this.mySwiper.slideTo(3, 1000, false)
  },
};
</script>

<style lang="scss">
//Swiper
.swiper-slide.news {
  width: 100%;
  height: 340px;

  padding-right: 0;
  padding-left: 0;
}
.newsw {
  margin-bottom: 30px;
}

//news Swiper
.img-wrapper {
  height: 210px;
  width: 100%;
  overflow: hidden;
  position: relative;

  border-radius: 10px;
}
.news-img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}
.news-title {
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
}
.news-date {
  color: $white;
  text-align: center;
  margin: 0;
  font-size: 14px;
}
.news-subtitle {
  color: $white;
  text-align: center;
  margin: 0;
}
.news-content {
  padding-top: 15px;
}

.news {
  padding-bottom: 100px;
  position: relative;
}
#cloudNews {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
}
</style>
