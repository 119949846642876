<template>
  <div>
    <div class="container">
      <div class="description row align-items-center justify-content-center">
        <b-col cols="12" md="8">
          <h2 class="text-center block_title" v-html="title"></h2>
          <p class="text-small text-center" v-html="description"></p>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.text-small {
  color: rgba($white, 0.6);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.description {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
