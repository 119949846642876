import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("@/views/Services.vue"),
  },
  {
    path: "/bussines_model",
    name: "BussinesModel",
    component: () => import("@/views/BussinesModel.vue"),
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("@/views/Company.vue"),
  },
  {
    path: "/applicant",
    name: "Applicant",
    component: () => import("@/views/Applicant.vue"),
  },
  {
    path: "/vacancies",
    name: "Vacancies",
    component: () => import("@/views/VacanciesView.vue"),
  },
  {
    path: "/partner",
    name: "Partner",
    component: () => import("@/views/Partner.vue"),
  },
  {
    path: "/partner_list",
    name: "Partner_list",
    component: () => import("@/views/PartnerList.vue"),
  },

  {
    path: "/happy-new-year",
    name: "HappyNewYear",
    component: () => import("@/views/HappyNewYear.vue"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("@/views/Events.vue"),
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    component: () => import("@/views/DashboardView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        //, offset: { x: 0, y: 10 }
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
