<template>
  <div class="effectivity">
    <b-container>
      <b-row class="effi-content">
        <b-col
          cols="12"
          md="4"
          class="exp-card"
          v-for="(item, index) in effData"
          :key="index"
        >
          <div class="effectivity__card">
            <div class="effectivity__card__icon_wrapper">
              <img
                class="effectivity__card__icon"
                :src="require('@/assets/svg/' + item.img)"
              />
            </div>
          </div>

          <h2 class="effectivity_title text-center" v-html="item.title"></h2>

          <p class="text-small effectivity_text text-center">
            {{ item.description }}
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Effectivity",
  data() {
    return {
      effData: [
        {
          title: "Понимаем внутренние процессы <br />и потребности заказчика",
          description: `Обладаем 10-летним опытом на рынке тендерного снабжения, как со стороны заказчика, так и со стороны поставщика.`,
          img: "icon-md-handshake.svg",
        },
        {
          title: "Ведём непрерывную<br /> коммуникацию с заказчиком",
          description: `Выстраиваем долгосрочные отношения с каждым заказчиком, поэтому всегда на связи и готовы приступить к выполнению даже срочных задач.`,
          img: "icon-md-laptop.svg",
        },
        {
          title: "Держим фокус<br /> на автоматизации",
          description:
            "Автоматизируем необходимые процессы для повышения качества оказываемых услуг. ",
          img: "icon-md-focus.svg",
        },
        {
          title: "Работаем на опережение<br /> и берёмся за срочную работу",
          description: `
          Включаемся на стадии планирования закупки. Решаем срочные задачи. 
          `,
          img: "icon-md-stapwatch.svg",
        },
        {
          title: "Принимаем быстрые решения<br /> и упрощаем процессы",
          description:
            "Договариваемся в моменте и тут же начинаем действовать. Непрерывно оптимизируем бизнес-процессы, снижая бюрократическую волокиту.",
          img: "icon-md-doc_pie.svg",
        },
        {
          title: "Предоставляем выгодные<br /> условия",
          description: `
          Возможна отсрочка платежа до 60 дней. Берём на себя вопросы по хранению товара, замораживаем стоимость на длительный срок. 
          `,
          img: "icon-md-docs-box.svg",
        },
        {
          title: "Создаём спокойную среду",
          description:
            "Предоставляем заказчику доступ в личный кабинет, где в режиме реального времени можно следить за статусом исполнения контракта.",
          img: "icon-md-monitor.svg",
        },
        {
          title: "Формируем уверенность",
          description: `
          Работаем качественно. Нам доверяют 10 компаний федерального уровня.
          `,
          img: "icon-md-conversation.svg",
        },
        {
          title: "Проявляем инициативу и заботу",
          description: `
          Действуем на опережение, помогая заказчику определить самые оптимальные варианты решения задачи.
          `,
          img: "icon-md-hand_lamp.svg",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.effectivity {
  padding-bottom: 100px;
}
.effectivity__card {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 100%;

  border-radius: 10px;
  border: 2px solid $gray-light;
  background-color: $gray;
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.effectivity__card__icon {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}
.effectivity__card__icon_wrapper {
  height: 90px;
  width: 90px;
  position: relative;
  overflow: hidden;
}
/* effi */
.effi-content {
  padding-bottom: 30px;
}
.effi-card-img {
  width: 100%;
  margin-bottom: 15px;

  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-light;

  background-color: $gray;
}
.effectivity_title {
  font-size: 16px;
  font-weight: 500;
}
.effectivity_text {
  margin-bottom: 30px;
}
</style>
