<template>
  <div class="partners_cards_lists">
    <LineInfo :title="title" :description="description" />

    <div class="partner_list">
      <b-container class="partner_list__bg">
        <div class="partner-circle-logo-absolute d-none d-md-block">
          <img class="partner-circle-logo-absolute-img d-none d-md-block" :src="require('@/assets/svg/logo-smaterials.svg')">
        </div>

        <b-row>
          <b-col cols="12" md="6">
            <div class="partner_list__img_wrapper">
              <img
                class="partner_list__img"
                :src="require(`@/assets/${top.img}`)"
              />
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="partner_list__content_wrapper">
              <b-col>
                <b-row align-h="center">
                  <b-col cols="12" md="10" class="partner_list__content">
                    <h2 class="partner_list__title">{{ top.title }}</h2>

                    <p class="partner_list__text">{{ top.text }}</p>
                  </b-col>
                </b-row>

                <b-row align-h="center" v-if="modal">
                  <b-col cols="auto">
                    <button
                      @click="setActive(0)"
                      v-b-modal.modal_text
                      id="button_outline"
                    >
                      Подробнее
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <div class="partner_list__content_wrapper">
              <b-col>
                <b-row align-h="center">
                  <b-col cols="12" md="10" class="partner_list__content">
                    <h2 class="partner_list__title">{{ bottom.title }}</h2>

                    <p class="partner_list__text">{{ bottom.text }}</p>
                  </b-col>
                </b-row>

                <b-row align-h="center" v-if="modal && modal.length == 2">
                  <b-col cols="auto">
                    <button
                      @click="setActive(1)"
                      v-b-modal.modal_text
                      id="button_outline"
                    >
                      Подробнее
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="partner_list__img_wrapper">
              <img
                class="partner_list__img"
                :src="require(`@/assets/${bottom.img}`)"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-modal
      v-if="modal"
      id="modal_text"
      size="lg"
      hide-footer
      hide-header
      body-class="partner_list__body"
      modal-class="partner_list__modal"
      dialog-class="partner_list__dialog"
      centered
      content-class="partner_list__content"
    >
      <b-container>
        <button class="partner_modal__close" type="button" @click="close">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M5.00001 5L19 19"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <b-row align-h="center">
          <b-col cols="12" md="8">
            <p class="partner_modal__text" v-html="modal[active]"></p>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";

export default {
  name: "PartnerList",
  components: {
    LineInfo,
  },
  props: {
    title: String,
    description: String,
    top: Object,
    bottom: Object,
    modal: Array,
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("modal_text");
    },
    setActive(i) {
      this.active = i;
    },
  },
};
</script>

<style lang="scss">
.partner_list__img_wrapper {
  position: relative;
  overflow: hidden;

  height: 320px;
  width: 100%;
}
.partner_list__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.partner_list__content {
  padding-bottom: 15px;
}
.partner_list__title {
  font-size: 20px;
  font-weight: 500;
  color: $accent;
  margin: 0;
  padding-bottom: 15px;
}
.partner_list__text {
  font-size: 16px;
  font-weight: 400;
  color: $white;
  margin-bottom: 0;
}

.partner_list__title.end {
  text-align: right;
}
.partner_list__text.end {
  text-align: right;
}
.partner_list__content_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.partner_list {
  background-color: $gray;
  margin-bottom: 100px;
}

.partner_list__bg {
  position: relative;
}

.partner_list__body,
.partner_list__header {
  background-color: rgba($gray, 0.96);
}

.modal-body.partner_list__body {
  border-radius: 15px;
  padding: 60px 0;
}

.modal-content.partner_list__content {
  background-color: unset;
  border: none;
}

.partner_modal__main_title {
  color: $white;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}
.partner_modal__title {
  color: $white;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.partner_modal__text {
  color: $gray__ultra_light;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.partner_modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px;
}

.partner-circle-logo-absolute {
  position: absolute;
  z-index: 100;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 120px;
  width: 120px;

  overflow: hidden;

  background-color: rgba($gray-dark, 1);
  border-radius: 100%;

}
.partner-circle-logo-absolute-img {
  position: absolute;

  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  object-fit: contain;

  height: 60%;
  width: 60%;
}
</style>
