<template>
  <div class="home">
    <HomeHeader />

    <img id="cloud" :src="require('@/assets/img/cloud.png')" alt="" />

    <WelcomeHome :slides="slides" />

    <LineInfo
      title="Комплексные поставки для крупного бизнеса"
      description="Только высокое качество и профессионализм"
    />

    <Doings />

    <Deliveries />

    <div class="rictangle-canvas">
      <img id="rictangle" src="@/assets/img/rictangle.png" alt="" />

      <PartnerList :title="title" :top="top" :bottom="bottom" :modal="modal" />

      <Services />

      <LineInfo
        title="2020 год в цифрах"
        description="Высокое качество и профессионализм"
      />

      <Prices />
    </div>

    <img id="cloudBM" :src="require('@/assets/img/cloud.png')" alt="" />
    <BussinesModel />

    <img id="rictanglePartner" src="@/assets/img/rictangle.png" alt="" />

    <PartnerCards />

    <ExpCases />

    <LineInfo title="Наша цель – довольный заказчик" />

    <img id="rictangleEffectivity" src="@/assets/img/rictangle.png" alt="" />
    <img
      id="cloudEffectivity"
      :src="require('@/assets/img/cloud.png')"
      alt=""
    />
    <Effectivity />

    <ContactForm />

    <LineInfo title="Дополнительно о компании" />

    <MoreInfo />

    <!-- <News /> -->
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import PartnerList from "@/components/PartnerList.vue";
import News from "@/components/News.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import PartnerCards from "@/components/SwiperCardsLogo.vue";
import LineInfo from "@/components/LineInfo.vue";
import Effectivity from "@/components/Effectivity.vue";
import ExpCases from "@/components/ExpCases.vue";
import HomeHeader from "@/components/HomeHeader.vue";
import WelcomeHome from "@/components/WelcomeHome.vue";
import Doings from "@/components/Doings.vue";
import Deliveries from "@/components/Deliveries.vue";
import Services from "@/components/Services.vue";
import Prices from "@/components/Prices.vue";
import BussinesModel from "@/components/BussinesModel.vue";

export default {
  name: "Home",
  components: {
    ContactForm,
    News,
    MoreInfo,
    PartnerCards,
    LineInfo,
    Effectivity,
    ExpCases,
    HomeHeader,
    WelcomeHome,
    Doings,
    Deliveries,
    Services,
    Prices,
    BussinesModel,
    PartnerList,
  },
  data() {
    return {
      slides: [
        {
          img: "home/director2.jpg",
          title: "Мы дарим вам время",
          text: `
          Время - ценнейший ресурс. И мы дарим его вам, взяв на себя обязательства по организации поставки товаров и/или услуг. Знаем, как устроить процесс системно, оперативно и качественно.<br />
          <br />
          Наш 10-летний опыт в сфере поставок позволяет выполнить обязательства с высокой эффективностью, и закрыть любую вашу потребность.<br />
          <br />
          Процесс работы выстроен чётко: внедрены ИТ-системы в бизнес для упрощения работы; каждый член команды работает максимально быстро и качественно, наши процессы оптимизированы и автоматизированы, что позволяет избежать лишней работы или выполнения одной и той же работы по 2 раза. Мы нацелены на результат.<br />
          <br />
          Мы растём и развиваемся, открыты и прозрачны. Мы за то, чтобы нести пользу, выстраивая долгосрочные отношения.
          `,
          sign: "Константин Миронов.",
        },
      ],

      title: "С заботой и вниманием – надолго",

      top: {
        img: "photo/_S__3094.jpg",
        title: "Комплексные решения",
        text: `
        Мы сотрудничаем с более чем 100 поставщиками и производителями. Нашими партнерами являются 14 компаний, которые прошли наш контроль. Это позволяет успешно исполнять контракты с многообразием товаров или широким перечнем услуг. 
        `,
      },
      bottom: {
        img: "photo/_S__3136.jpg",
        title: "Фундаментальность ценностей",
        text: `
         Проявляем заботу и внимание к каждому заказчику без исключения. Такой подход позволяет выстраивать долгосрочные внутренние и внешние отношения, основанные на доверии, открытости, уважении.
        `,
      },

      modal: [
        `
        Мы обеспечиваем комплексные поставки – несколько категорий товаров и услуг могут быть включены в один контракт. Например, если вам необходимо поставить офисную мебель, принтеры и выполнить монтаж противопожарной сигнализации, мы легко справимся с задачей.`,
      ],
    };
  },
};
</script>

<style lang="scss">
.home {
  position: relative;
  overflow: hidden;
}

#cloud {
  position: absolute;
  height: 70vh;
  left: 50%;
  top: 12%;
  transform: translate(-50%, 0);
}
#cloudBM {
  position: absolute;
  height: 70vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
}
#cloudEffectivity {
  position: absolute;
  height: 70vh;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, 0);
}
#rictangle {
  height: 140vh;
  position: absolute;
  left: 100%;
  top: 60%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
#rictanglePartner {
  height: 140vh;
  position: absolute;
  left: 0%;
  top: 60%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
#rictangleEffectivity {
  height: 140vh;
  position: absolute;
  left: 100%;
  bottom: 8%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
</style>
