<template>
  <div class="fixed-top navbar_background">
    <!-- desktop -->
    <b-container class="d-none d-xl-block" fluid>
      <b-row align-h="between" align-v="center" class="navbar_content">
        <b-col cols="12" sm="auto">
          <div class="logo_data">
            <button type="button" @click="to('Home')" class="logo_wrapper">
              <img
                class="logo_img"
                :src="require('@/assets/svg/logo-smaterials.svg')"
                alt=""
              />
            </button>
          </div>
        </b-col>

        <b-col cols="12" sm="auto">
          <div class="links">
            <router-link
              v-for="(link, index) in links"
              :key="index"
              :to="link.path"
              :class="$route.path == link.path ? 'active' : ''"
              >{{ link.title }}</router-link
            >
          </div>
        </b-col>

        <b-col cols="12" sm="auto">
          <b-row calss="contacts">
            <div class="contacts_info">
              <!-- <p class="call_text">+7 (965) 897 05-51</p> -->
              <!-- <p>г.Москва, ул.Таллинская 25</p> -->
            </div>

            <a v-b-modal.callme class="call_wrapper">
              <img class="call" :src="require('@/assets/svg/icon-phone.svg')" />
            </a>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- mobile -->
    <b-container class="d-xl-none">
      <b-row align-v="center" align-h="between" class="mobile_navbar__content">
        <b-col cols="auto">
          <div class="logo_data">
            <button type="button" @click="to('Home')" class="logo_wrapper">
              <img
                class="logo_img"
                :src="require('@/assets/svg/logo-smaterials.svg')"
                alt=""
              />
            </button>

            <!-- <p class="logo_title">С-МАТЕРИАЛС</p> -->
          </div>
        </b-col>

        <b-col cols="auto">
          <a class="mobile_navbar__burger_wrapper" v-b-modal.menu>
            <svg
              class="mobile_navbar__burger"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 7H20M4 12H20M4 17H20"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </b-col>

        <!-- <b-col cols="auto">
            <a v-b-modal.callme class="img_call__wrapper">
              <img
                class="img_call__img"
                :src="require('@/assets/svg/icon-phone.svg')"
              />
            </a>
          </b-col> -->
      </b-row>
    </b-container>

    <!-- modal_menu -->
    <b-modal
      id="menu"
      centered
      hide-footer
      hide-header
      size="xl"
      body-class="mobile_menu__body"
      content-class="mobile_menu__content"
      modal-class="mobile_menu__modal"
    >
      <button class="partner_modal__close" type="button" @click="close">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 5L5 19M5.00001 5L19 19"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <b-container>
        <b-row align-h="center" class="mobile_navbar__logo_wrapper">
          <b-col cols="auto">
            <div class="logo_data">
              <button type="button" @click="to('Home')" class="logo_wrapper">
                <img
                  class="logo_img"
                  :src="require('@/assets/svg/logo-smaterials.svg')"
                  alt=""
                />
              </button>

              <p class="logo_title">С-МАТЕРИАЛС</p>
            </div>
          </b-col>
        </b-row>

        <div class="menu_links">
          <b-row align-h="center" v-for="(link, index) in links" :key="index">
            <router-link
              :to="link.path"
              :class="$route.path == link.path ? 'active' : ''"
            >
              <p class="mobile_menu_link" @click="close">{{ link.title }}</p>
            </router-link>
          </b-row>
        </div>

        <b-row align-h="center">
          <a v-b-modal.callme class="mobile_navbar__callme_wrapper">
            <img
              class="mobile_navbar__callme"
              :src="require('@/assets/svg/icon-phone.svg')"
            />
          </a>
        </b-row>

        <b-row align-h="center" class="modal_menu_contact">
          <div class="contact_info__block">
            <p class="contact_info text-center">+7 (965) 897 05-51</p>
            <p class="contact_info text-center">
              г. Красноярск,<br />
              ул. Партизана Железняка, 35А
            </p>
          </div>
        </b-row>

        <b-row align-h="center" align-v="center" class="block_soc">
          <div class="col d-flex justify-content-center align-items-center">
            <a>
              <img
                id="icon_soc_mobile"
                :src="require('@/assets/img/icons/social/icon_fb.png')"
                alt="facebook"
              />
            </a>

            <a href="https://www.instagram.com/supplymaterials" target="_blank">
              <img
                id="icon_soc_mobile"
                :src="require('@/assets/img/icons/social/icon_inst.png')"
                alt="instagram"
              />
            </a>

            <a>
              <img
                id="icon_soc_mobile"
                :src="require('@/assets/img/icons/social/icon_vk.png')"
                alt="vk"
              />
            </a>
          </div>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          title: "Заказчику",
          path: "/",
        },
        {
          title: "Товары и услуги",
          path: "/services",
        },
        {
          title: "Бизнес-модель",
          path: "/bussines_model",
        },
        {
          title: "Компания",
          path: "/company",
        },
        {
          title: "Соискателю",
          path: "/applicant",
        },
        {
          title: "Вакансии",
          path: "/vacancies",
        },
        {
          title: "Партнерам",
          path: "/partner",
        },
        {
          title: "Список партнеров",
          path: "/partner_list",
        },
      ],
    };
  },
  methods: {
    to(name) {
      if (this.$route.name != name) this.$router.push({ name: name });
    },
    close() {
      this.$bvModal.hide("menu");
    },
  },
};
</script>

<style lang="scss">
/* navbar */
.navbar_background {
  background-color: rgba($color: $gray, $alpha: 0);
  backdrop-filter: blur(4px);
}
.navbar_content {
  height: 64px;
}
.navbar-canvas {
  height: 64px;
}
.mobile .navbar_content {
  padding-right: 15px;
  padding-left: 15px;
}

/* logo */
.logo {
  height: 40px;
  max-width: 100%;
}

/* nav links */
.links a {
  font-weight: 400;
  font-size: 15px;

  text-decoration: none;
  color: rgba($white, 0.8);
  text-shadow: $shadow;

  padding: 10px 0;
  margin-left: 7px;
  margin-right: 7px;
}
.links a:hover {
  color: rgba($white, 1);
}
.links a.router-link-exact-active,
a.active {
  color: $accent;
}

.contacts {
  margin-right: 15px;
}
/* contacts navbar */
// .contacts_info p {
//   padding-right: 10px;
//   color: $white;
//   font-size: 14px;
//   font-weight: 500;
//   margin: 0;
// }
.call_text {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding-right: 10px;
  line-height: 18px;
}
.contacts_info {
  display: flex;
  align-items: center;
}

/* modal */
#menu .logo {
  margin-bottom: 60px;
  height: 70px;
}
.call {
  height: 100%;
  width: 100%;
  position: absolute;
}
.call_wrapper {
  margin-right: 15px;
  margin-left: 15px;
  height: 40px;
  width: 40px;
  object-fit: contain;
  position: relative;
}
.menu_links {
  padding-top: 30px;
  margin-bottom: 60px;
}
#menu .block_soc {
  padding-top: 30px;
  padding-bottom: 30px;
}
.icon_soc {
  filter: opacity(70%);
  width: 38px;
  margin-left: 10px;
  margin-right: 10px;
}

.icon_soc:hover {
  filter: opacity(85%);
}

#menu a {
  color: $white;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1px;
}
.modal_menu_contact p {
  color: $white;
  font-weight: 500;
  margin: 0px;
}
#menu a.router-link-exact-active p {
  color: $accent;
}

.modal-content {
  background: none;
  border-radius: 0;
  border: none;
}
.modal-backdrop {
  opacity: 0.95;
  background-color: $gray-dark;
}
.modal {
  backdrop-filter: blur(7px);
}
.fade {
  transition: opacity 0.15s linear;
}
.modal-header {
  border-bottom: none;
}
.modal-header .close {
  color: $white;
}

.logo_wrapper {
  height: 50px;
  width: 50px;
  position: relative;
  overflow: hidden;
}
.logo_img {
  height: 100%;
  width: 100%;
}
.logo_data {
  display: flex;
  align-items: center;
}
.logo_title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

/////////////////////////////////////////////
//mobile new styles
.mobile_navbar__content {
  padding: 10px 15px;
}
.mobile_navbar__burger_wrapper {
  height: 60px;
  width: 60px;
  position: relative;
  overflow: hidden;
}

.modal-content.mobile_menu__content {
  min-height: 100vh;
  padding: 15px;
  margin: 0;
  border: 0;
  background-color: unset;
}
.modal.mobile_menu__modal {
  background-color: rgba($color: $gray, $alpha: 0.9);
}
.mobile_navbar__callme_wrapper {
  height: 60px;
  width: 60px;
  position: relative;
  margin-bottom: 15px;
}
.mobile_navbar__callme {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.partner_modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px;
}
.mobile_navbar__logo_wrapper {
  padding-top: 60px;
}

.mobile_menu_link {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
}
</style>
