import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import axiosRateLimit from "axios-rate-limit";

Vue.use(Vuex);

const axiosBitrix = axiosRateLimit(axios.create(), {
  maxRequests: 10,
  perMilliseconds: 1000,
  maxRPS: 2,
});

const hook = "https://dbmaterials.bitrix24.ru/rest/342/vuo2mv6k814w22m6/";

export default new Vuex.Store({
  state: {
    navbar: true,
    footer: true,
  },
  mutations: {
    SET_NAVBAR(state, payload) {
      state.navbar = payload;
    },
    SET_FOOTER(state, payload) {
      state.footer = payload;
    },
  },
  actions: {
    async sendForm(state, data) {
      try {
        let result = await axiosBitrix.post(hook + "crm.contact.add.json", {
          fields: {
            NAME: data.name,
            TYPE_ID: "CLIENT",
            PHONE: [
              {
                VALUE: data.phone,
                VALUE_TYPE: "WORK",
              },
            ],
          },
        });
        let contactID = result.data.result;

        await axiosBitrix.post(hook + "crm.deal.add.json", {
          fields: {
            CATEGORY_ID: 96,
            TITLE: data.title,
            CONTACT_ID: contactID,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async sendApplicantForm(state, data) {
      let result = await axiosBitrix.post(hook + "crm.contact.add.json", {
        fields: {
          NAME: data.name,
          TYPE_ID: "CLIENT",
          PHONE: [
            {
              VALUE: data.phone,
              VALUE_TYPE: "WORK",
            },
          ],
        },
      });
      let contactID = result.data.result;

      await axiosBitrix.post(hook + "crm.deal.add.json", {
        fields: {
          CATEGORY_ID: 96,
          TITLE: data.title,
          CONTACT_ID: contactID,
          UF_CRM_1670833036767: data.vacancy,
          UF_CRM_1628349646020: data.portfolio,
        },
      });
    },
  },
});
