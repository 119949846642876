<template>
  <b-modal
    id="callme"
    size="lg"
    hide-footer
    hide-header
    body-class="partner_list__body"
    modal-class="partner_list__modal"
    dialog-class="partner_list__dialog"
    centered
    content-class="partner_list__content"
  >
    <b-container>
      <button class="partner_modal__close" type="button" @click="close">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 5L5 19M5.00001 5L19 19"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <b-container>
        <b-row align-h="center" align-v="center" class="call_logo_wrapper">
          <div class="callme__wrapper_logo">
            <img
              class="callme__logo"
              :src="require('@/assets/svg/logo-smaterials.svg')"
              alt=""
            />
          </div>

          <p class="logo_title">С-МАТЕРИАЛС</p>
        </b-row>

        <b-form @submit="sendForm">
          <b-row align-v="center" align-h="center">
            <b-col cols="12" md="8">
              <h3 class="callme__title">
                Сформируем для Вас коммерческое предложение под комплексную
                закупку с возможностью отсрочки платежа до 60 дней!
              </h3>
              <p class="callme__text">
                Отставьте заявку и мы оперативно подготовим коммерческое
                предложение под вашу закупку, которая предполагает множество
                наименований товаров и услуг.
              </p>

              <div class="callme__form">
                <div class="callme__inputs">
                  <input
                    v-model="form.name"
                    id="name_modal"
                    class="callme__input"
                    type="text"
                    placeholder="Введите Ваше имя"
                    required
                  />
                  <input
                    v-model="form.phone"
                    id="phone_modal"
                    class="callme__input"
                    type="tel"
                    placeholder="Введите Ваш телефон"
                    required
                  />
                </div>

                <b-row align-h="center">
                  <b-col cols="6" md="6">
                    <button
                      block
                      @click="close"
                      type="button"
                      class="callme_button close_button"
                    >
                      Отмена
                    </button>
                  </b-col>

                  <b-col cols="6" md="6">
                    <button
                      block
                      :disabled="disabled"
                      type="submit"
                      class="callme_button"
                    >
                      {{ buttonText }}
                    </button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: "CallMe",
  data() {
    return {
      form: {
        name: null,
        phone: null,
        title: 'Форма "Коммерческое предложение"',
      },

      disabled: false,
      buttonText: "Отправить",
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("callme");
    },
    async sendForm(ev) {
      ev.preventDefault();

      this.disabled = true;

      try {
        await this.$store.dispatch("sendForm", this.form);

        this.buttonText = "Отправлено";
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.call_logo_wrapper {
  padding-bottom: 30px;
}
.callme__wrapper_logo {
  height: 80px;
  width: 80px;
  position: relative;
  overflow: hidden;
}
.callme__logo {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}
.partner_list__body {
  border-radius: 15px;
  padding: 60px 0;
  background-color: rgba($gray, 0.9);
  border: 1px solid $accent;
}

.partner_list__content {
  background-color: unset;
  border: none;
}
.partner_list__dialog {
  background-color: unset;
  border: none;
}
.partner_list__modal {
  background-color: unset;
  border: none;
}

.partner_modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px;
}

.callme__title {
  color: $white;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}
.callme__text {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: $gray__medium_light;
  margin-bottom: 60px;
}

.callme_button {
  background-color: unset;
  width: 100%;
  color: $white;
  font-weight: 300;
  border: 1px solid $accent;
  border-radius: 5px;
  font-size: 16px;
  height: 44px;
  margin-bottom: 15px;
}
.callme_button:hover {
  background-color: rgba($color: $accent, $alpha: 0.8);
}
.callme_button.close_button {
  border-color: $gray__medium_light;
}
.callme_button.close_button:hover {
  background-color: rgba($color: $gray-light, $alpha: 0.8);
}

.callme__input {
  width: 100%;
  background-color: unset;
  border: 1px solid $gray-light-darken;
  border-radius: 5px;
  height: 44px;
  margin-bottom: 15px;
  text-align: center;
  color: $gray__medium_light;
  font-weight: 300;
}

.callme__input:focus,
.callme__input:focus-visible {
  outline: none;
  box-shadow: none;
  border: 1px solid $gray__ultra_light;
  color: $gray__ultra_light;
}
.callme__inputs {
  padding-bottom: 30px;
}
</style>
