<template>
  <div class="partner_cards">
    <LineInfo
      class="title"
      title="Наши партнёры"
      description="
      На сегодняшний день наша партнёрская сеть насчитывает 14 компаний.<br /> Доступны более 1000 категорий товаров и видов услуг.
      "
    />

    <b-container>
      <b-row>
        <b-col cols="12">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper partner_cards__wrapper">
              <div
                class="swiper-slide partner_cards__slide"
                v-for="(item, index) in partners"
                :key="index"
              >
                <div class="partner_cards__logo_card">
                  <div class="partner_cards__logo_wrapper">
                    <img
                      class="partner_cards__logo_img"
                      :src="
                        require('@/assets/img/SwiperCardsLogo/' + item.logo1)
                      "
                    />
                  </div>
                </div>

                <div class="partner_cards__logo_card">
                  <div class="partner_cards__logo_wrapper">
                    <img
                      class="partner_cards__logo_img"
                      :src="
                        require('@/assets/img/SwiperCardsLogo/' + item.logo2)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";

export default {
  name: "SwiperCardsLogo",
  components: {
    LineInfo,
  },
  data() {
    return {
      partners: [
        {
          logo1: "dlinkLogo.png",
          logo2: "bk.png",
        },
        {
          logo1: "bewardLogo.png",
          logo2: "mk.png",
        },
        {
          logo1: "poligon.png",
          logo2: "orta.png",
        },
        {
          logo1: "videoservice.png",
          logo2: "sibtehstroy.png",
        },
        {
          logo1: "hobatex.png",
          logo2: "proclimate.png",
        },
      ],
      swiperOption: {
        mousewheel: {
          forceToAxis: true
        },
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.swiper_cards_logo__wrapper {
  margin-bottom: 30px;
}
//Cards
.partner_cards {
  padding-bottom: 100px;
}

.partner_cards__logo_card {
  margin: 10px 5px;
  background-color: rgba($gray-light, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.partner_cards__logo_wrapper {
  width: 50%;
  height: 45%;
  position: relative;
  overflow: hidden;
  margin: 30px;
}
.partner_cards__logo_img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
}
</style>
