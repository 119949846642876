<template>
  <div class="welcome_home">
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper welcome_home__swiper__wrapper">
              <div
                v-for="(item, index) in slides"
                :key="index"
                class="swiper-slide"
              >
                <b-row align-h="center" align-v="center">
                  <b-col cols="12" md="4">
                    <div class="welcome_home__swiper__img_wrapper">
                      <img
                        class="welcome_home__swiper__img"
                        :src="require('@/assets/img/' + item.img)"
                        alt=""
                      />
                    </div>
                  </b-col>

                  <b-col cols="12" md="7">
                    <b-row align-v="center" align-h="center">
                      <b-col cols="12" md="10">
                        <h2 class="welcome_home__swiper__title block_title">
                          {{ item.title }}
                        </h2>

                        <p
                          class="welcome_home__swiper__text"
                          v-html="item.text"
                        ></p>

                        <div class="welcome_home__swiper__stamp">
                          <h3 class="welcome_home__swiper__sign">
                            {{ item.sign }}
                          </h3>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="swiper-pagination"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WelcomeHome",
  props: {
    slides: Array,
  },
  data() {
    return {
      swiperOption: {
        cssMode: true,
        mousewheel: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.welcome_home__swiper__wrapper {
  height: 100%;
  padding-bottom: 30px;
}
.welcome_home {
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
}

//Swiper
.welcome_home__swiper__img_wrapper {
  width: 100%;
  height: 520px;

  position: relative;
  overflow: hidden;
}
.welcome_home__swiper__img {
  height: 100%;
  width: 100%;

  position: absolute;
  object-fit: cover;
}
.welcome_home__swiper__title {
  padding-bottom: 15px;
}
.welcome_home__swiper__text {
  color: $white;
  font-style: italic;
}

.welcome_home__swiper__sign {
  color: $accent;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}
.welcome_home__swiper__position {
  text-align: left;
}
</style>
