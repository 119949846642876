<template>
  <div class="exp-cases">
    <LineInfo
      title="Опыт в виде кейсов"
      description="Повышаем эффективность вашего бизнеса"
    />

    <div class="container">
      <div class="row">
        <div class="col-md-4 exp-card">
          <div class="exp_case__img_wrapper">
            <img
              src="@/assets/img/home/exp_cases/askye.jpg"
              class="exp_case__img"
            />
          </div>
          <h2 class="exp-card_title text-center">АСКУЭ</h2>
          <p class="text-small text-center">
            Поставка, ШМР и ПНР ПТК коммерческого учёта тепловой и электрической
            энергии<br />
            АО "Востсибнефтегаз"
          </p>

          <p class="case_price">69 473 684 руб.</p>
        </div>

        <div class="col-md-4 exp-card">
          <div class="exp_case__img_wrapper">
            <img
              src="@/assets/img/home/exp_cases/erp.jpg"
              class="exp_case__img"
            />
          </div>
          <h2 class="exp-card_title text-center">ЕРП</h2>

          <p class="text-small text-center">
            Поставка программно-аппаратного комплекса для телефонной станции
            (АТС Avaya) АО "Енисейское речное пароходство"
          </p>

          <p class="case_price">13 102 355 руб.</p>
        </div>

        <div class="col-md-4 exp-card">
          <div class="exp_case__img_wrapper">
            <img
              src="@/assets/img/home/exp_cases/comp.jpg"
              class="exp_case__img"
            />
          </div>

          <h2 class="exp-card_title text-center">НН-С</h2>

          <p class="text-small text-center">
            Комплексная поставка более чем 1000 наименований позиций "Норильск
            <br />Никель Сервис"
          </p>
          <p class="case_price">60 000 000 руб.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";

export default {
  name: "ExpCases",
  components: {
    LineInfo,
  },
};
</script>

<style lang="scss">
.case_price {
  font-size: 18px;
  text-align: center;
  padding-top: 15px;
}
.exp-cases {
  padding-bottom: 100px;
}
/* exp */
.exp-card {
  padding-bottom: 15px;
}
.exp-card_title {
  font-weight: 700;
  font-size: 16px;
  color: $white;
  padding-bottom: 15px;
  margin: 0;
  text-transform: uppercase;
}
.exp_case__img_wrapper {
  height: 200px;
  width: 100%;
  position: relative;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
}
.exp_case__img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
</style>
