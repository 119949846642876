<template>
  <div class="prices">
    <div class="prices_cont container">
      <div class="row">
        <div class="col-md-3">
          <div class="row justify-content-center">
            <div class="circle-logo">
              <img :src="require(`@/assets/svg/icon-money-line.svg`)">
            </div>
          </div>

          <h2 class="title text-center">20 473 000</h2>

          <p class="text-small text-center">объём продаж в рублях</p>
        </div>

        <div class="col-md-3">
          <div class="row justify-content-center">
            <div class="circle-logo">
              <img :src="require(`@/assets/svg/icon-doc-handshake.svg`)">
            </div>
          </div>

          <h2 class="title text-center">102</h2>

          <p class="text-small text-center">реализованных контрактов</p>
        </div>

        <div class="col-md-3">
          <div class="row justify-content-center">
            <div class="circle-logo">
              <img :src="require(`@/assets/svg/icon-lence-man.svg`)">
            </div>
          </div>

          <h2 class="title text-center">10</h2>

          <p class="text-small text-center">ключевых заказчиков</p>
        </div>

        <div class="col-md-3">
          <div class="row justify-content-center">
            <div class="circle-logo">
              <img :src="require(`@/assets/svg/icon-peoples-round.svg`)">
            </div>
          </div>

          <h2 class="title text-center">14</h2>

          <p class="text-small text-center">компаний-партнеров</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Prices",
};
</script>

<style lang="scss">
.prices {
  margin-bottom: 100px;
}
.sum_icon {
  height: 76px;
  width: 76px;
}
.prices_cont {
  background-color: $gray;
  padding: 40px;
  z-index: 2;
  position: relative;

  border: 1px solid $gray-light;
}
.circle-logo {
  height: 148px;
  width: 148px;
  margin: 15px;

  border-radius: 10px;
  border-width: 3px;
  border-color: $gray-light;
  border-style: solid;

  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-logo-img {
  display: block;
  max-width: 65%;
}
.prices .title,
.prices .text-small {
  padding-bottom: 5px;
}
</style>
