<template>
  <div class="bussines-model">
    <b-container class="bussines-model-content">
      <b-row align-h="center" align-v="center">
        <b-col cols="12" lg="8">
          <h2 class="bussines-model title text-center block_title">
            Бизнес-модель, основанная на партнёрстве
          </h2>

          <p class="bussines-model text text-center">
            В основе бизнес-модели лежит партнёрская сеть поставщиков и
            производителей. Каждая компания-партнёр соответствует высоким
            стандартам качества. Это гарантирует поставку любых товаров или
            оказания любых видов услуг и высокий уровень надёжности на каждом
            этапе сделки.<br />
            Такой подход позволяет постоянно расширять спектр товаров и услуг,
            гарантируя успешное решение даже самых нестандартных задач
            заказчика.
          </p>
        </b-col>
      </b-row>

      <b-row align-v="center" align-h="center" class="bussines-model-button">
        <a @click="$router.push('/bussines_model')" id="button_outline"
          >Бизнес-модель</a
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "BussinesModel",
};
</script>

<style lang="scss">
.bussines-model {
  position: relative;
  z-index: 2;
  padding-bottom: 100px;
}
.bussines-model-content {
  padding-top: 50px;
  padding-bottom: 50px;
}
.bussines-model.title {
  padding-bottom: 15px;
}
.bussines-model.text {
  padding-bottom: 15px;
}
</style>
