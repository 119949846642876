<template>
  <div class="services">
    <b-container>
      <b-row align-h="center" align-v="start" class="services-block">
        <b-col cols="12" md="5">
          <img
            class="services-img"
            src="@/assets/img/home/services/services.jpg"
            alt=""
          />
        </b-col>

        <b-col cols="12" md="7" class="services-content">
          <b-row align-h="center" class="head-block">
            <b-col cols="12" md="9">
              <h2 class="services title block_title">Наши услуги</h2>

              <p class="services text-small">
                Делаем больше, чем вы ожидаете. Ответственно подходим к оказанию
                услуги на каждом этапе реализации закупочной процедуры: от
                формирования потребности до разгрузки товара.
              </p>
            </b-col>
          </b-row>

          <b-row align-h="center">
            <b-col cols="12" md="9">
              <div v-swiper:mySwiper="swiperOption">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in carouselItems"
                    :key="index"
                  >
                    <p
                      class="services text"
                      v-for="(txt, index) in item.text"
                      :key="index"
                    >
                      <span class="number_slide">{{ ++index }}.</span> {{ txt }}
                    </p>
                  </div>
                </div>

                <div class="swiper-pagination"></div>
              </div>
            </b-col>
          </b-row>

          <b-row align-h="center" class="footer-block align-self-bottom">
            <b-col cols="12" md="9">
              <p class="services-subtitle-button text text-center">
                Подробный перечень всех товаров и услуг, которые мы поставляем и
                оказываем, представлен здесь.
              </p>

              <b-row align-h="center">
                <a @click="$router.push('/services')" id="button_outline"
                  >Товары и услуги</a
                >
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      carouselItems: [
        {
          text: [
            "подбор материалов;",
            "расчёт расходов, выбранных материалов;",
            "заказ и покупка материалов;",
            "доставка материалов на объект;",
            "осуществление погрузочно-разгрузочных работ;",
            "контроль поставок;",
            "подготовка закупочной документации;",
          ],
        },
        {
          text: [
            "подача закупки;",
            "обработка результатов закупки и заключение контракта;",
            "предварительный сбор данных о потребностях, ценах на товары/работы/услуги;",
            "проверка соблюдений условий контракта;",
            "мониторинг в сфере закупок;",
            "проверка качества представленных товаров/работ/услуг;",
          ],
        },
      ],
      swiperOption: {
        mousewheel: {
          forceToAxis: true
        },
        autoplay: {
          delay: 3000
        },
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss">
/* services */
.services-block {
  padding-bottom: 100px;
}
.rictangle-canvas {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.services-img {
  width: 100%;
}
.services-subtitle-button {
  font-size: 14px;
}
.services.text {
  font-weight: 400;
  color: $white;
  font-size: 16px;
  line-height: 15px;
}
.services-content {
  padding-left: 5%;
  height: 100%;
}
.services.title {
  padding-bottom: 15px;
}
.services.text-small {
  padding-bottom: 30px;
}

.img-canvas {
  width: 100%;
  height: 100px;
  position: relative;
}
.img-canvas_img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: contain;
}

.services.second-title {
  padding-bottom: 15px;
}

.footer-block {
  padding-top: 30px;
}
.number_slide {
  color: $gray__medium_light;
  font-size: 14px;
  font-weight: 400;
}
</style>
