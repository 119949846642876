<template>
  <div class="header_home">
    <img
      class="header_home__img"
      :src="require('@/assets/img/home/header_wallpaper.jpg')"
      alt=""
    />

    <div class="header_home__img_filter"></div>

    <b-container>
      <b-row align-v="center" align-h="center" class="header_home__content">
        <b-col cols="12" class="content__data">
          <b-row align-h="center" align-v="center">
            <b-col cols="12" lg="9">
              <h1 class="header_title home_header_title">
                Комплексные тендерные поставки <br />с высоким уровнем сервиса
                для крупного российского бизнеса
              </h1>

              <p class="text_one">
                С 2020 года успешно решаем ответственные задачи в сфере
                тендерного снабжения для компаний федерального уровня.
                Осуществляем поставки более 1000 категорий товаров.
              </p>

              <p class="text_two">
                С-МАТЕРИАЛС - тендерные поставки качественно, надёжно, с
                сервисом.
              </p>
            </b-col>
          </b-row>
        </b-col>

        <div class="wrapper_icons">
          <div id="header_home__content__icons">
            <!-- <a href="#" target="_blank" rel="noopener noreferrer">
              <img class="icon_soc" src="@/assets/img/icons/social/icon_fb.png" alt="facebook" />
            </a> -->

            <a
              href="https://www.instagram.com/supplymaterials/"
              target="_blank"
            >
              <img
                class="icon_soc"
                src="@/assets/img/icons/social/icon_inst.png"
                alt="instagram"
              />
            </a>

            <a
              href="https://vk.com/s_materials"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="icon_soc"
                src="@/assets/img/icons/social/icon_vk.png"
                alt="vk"
              />
            </a>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
};
</script>

<style lang="scss">
.header_home {
  position: relative;
}
.header_home__img {
  height: 100%;
  width: 100%;

  position: absolute;
  z-index: 1;
  object-fit: cover;
}
.header_home__img_filter {
  position: absolute;
  z-index: 2;

  height: 100%;
  width: 100%;

  background-color: rgba($gray-dark, 0.7);
}
.header_home__content {
  padding-top: 150px;
  min-height: 100vh;
  align-items: center;

  position: relative;
  z-index: 2;
}
// #header_subtitle {
//   color: $gray-light;
//   font-size: 16px;
//   padding-bottom: 15px;
// }
#header_home__content__button {
  padding-bottom: 150px;
}
#header_home__content__icons {
  padding-bottom: 15px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 100%;

  display: flex;
  justify-content: center;

  transform: translate(-50%, 0);
}
.content__data {
  padding-bottom: 10vh;
}
</style>
